export default class Garden {
    constructor(instance, address) {
      this.address = address;
      this.instance = instance;
    }
  
    numberMinted() {
      return this.instance.numberMinted(this.address);
    }   
}
  