/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";

import { isEmpty } from "_dash";
import { getContractConfig } from "utils/contract";
import { ethToRegularString, getTxUrl } from "utils";
import { CONTRACTS, NETWORK } from "global_constants";

import Minter from "adpaters/minter";
import Garden from "adpaters/garden";

import Faq from "components/faq";
import Button from "components/button";
import ProviderHOC from "hoc/provider";
import useConnect from "hooks/useConnect";
import useProvider from "hooks/useProvider";
import AlertModal from "components/alertModal";

import cloudSvg from "assets/graphics/Clouds_1.svg";
import cloudBackSvg from "assets/graphics/Group-105.svg";
import gardenGif from "assets/graphics/Gardens-mint.gif";
import { ReactComponent as LogoSvg } from "assets/graphics/Logo.svg";

import "./index.css";

const App = ({ provider, setProvider }) => {
  const contracts = useProvider(provider);
  const [isConnected, , doConnect, connection] = useConnect(provider);

  const [contract, setContract] = useState();

  const [txHash, setTxHash] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [minting, setMinting] = useState(false);

  const [price, setPrice] = useState();
  const [mintCount, setMintCount] = useState(1);
  const [supplyCount, setSupplyCount] = useState(3000);
  const [currentRound, setCurrentRound] = useState(-1);

  const [userMinted, setUserMinted] = useState(0);

  const { seconds, minutes, restart } = useTimer({
    autoStart: false,
    onExpire: () => console.warn("stage expires"),
    expiryTimestamp: dayjs().add(1200, "seconds").valueOf(),
  });

  useEffect(() => {
    // doAutoConnect();
  }, []);

  useEffect(() => {
    if (connection) {
      setProvider(connection);
    }
  }, [connection]);

  function initialize(contract) {
    setContract(contract);
    const mintObj = new Minter(contract);

    mintObj.getSupplyLeft().then((supply) => {
      setSupplyCount(supply.toNumber());
    });

    mintObj.getPublicPrice().then((price) => {
      setPrice(price);
    });

    mintObj.getActiveRound().then((round) => {
      setCurrentRound(round.toNumber());      
    });
  }

  function getGardenInfo() {
    const gardenObj = new Garden(contracts[CONTRACTS.GARDEN], provider.address);
    gardenObj.numberMinted().then((num) => {
      setUserMinted(num.toNumber());
    });
  }

  useEffect(() => {
    const config = getContractConfig(
      NETWORK.TARGET_CHAIN_ID.toString(),
      CONTRACTS.GARDENMINTER
    );
    if (config) {
      const provider = new ethers.providers.JsonRpcProvider(
        process.env.REACT_APP_RPC_URL
      );
      const contract = new ethers.Contract(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        config.abi,
        provider
      );
      initialize(contract);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(contracts)) {
      getGardenInfo();
      provider.instance.on("block", () => {
        initialize(contracts[CONTRACTS.GARDENMINTER]);
        getGardenInfo();
      });
      return () => provider.instance.off("block");
    }
  }, [contracts]);

  useEffect(() => {
    if (price && contract) {
      const mintObj = new Minter(contract);
      mintObj.getPublicStartTime().then((startTime) => {
        mintObj.getPublicMintDuration().then((duration) => {            
          const _d = duration.toNumber();
          const diff = parseInt(Math.max(dayjs().unix() - startTime.toNumber(), 0) % _d);         
          restart(
            dayjs()
              .add(_d - diff, "seconds")
              .valueOf(),
            true
          );
        });
      });
    }
  }, [price]);

  function onIncrease() {
    if (mintCount < supplyCount && mintCount + userMinted < 5) {
      setMintCount((_) => _ + 1);
    }
  }

  function onDecrease() {
    if (mintCount > 1) {
      setMintCount((_) => _ - 1);
    }
  }

  async function onMint() {
    if (currentRound > 0) {
      setMinting(true);
      const mintObj = new Minter(
        contracts[CONTRACTS.GARDENMINTER],
        provider.address
      );
      const supplyLeft = await mintObj.getSupplyLeft();
      if (mintCount <= supplyLeft.toNumber()) {
        let tx = null;
        if (currentRound === 1) {
          const extraData = {
            value: price.mul(mintCount),
          };

          let userBalance = await provider.instance.getBalance(
            provider.address
          );
          if (userBalance.lt(extraData.value)) {
            setErrorMsg("You do not have enough ETH to complete the purchase");
          }

          try {
            tx = await mintObj.publicMint(
              provider.signer,
              mintCount,
              extraData
            );
          } catch (e) {
            console.log(e);
            setErrorMsg("An error occurred. Please try again.")
          }
        }
        if (currentRound === 2) {
          tx = await mintObj.claimMint(provider.signer, []);
        }
        if (tx) {
          setMintCount(1);
          setTxHash(tx.hash);
          await tx.wait();
        }
      }
    } else {
        console.log("show error msg")
        setErrorMsg("Minting starts June 28th 6PM CEST.");
    }
    setMinting(false);
    setTxHash(undefined);
  }

  return (
    <>
      <div className="h-full">
        <div
          className="py-12"
          style={{ backgroundImage: `url(${cloudBackSvg})` }}
        >
          <LogoSvg className="m-auto" />
          <div className="container m-auto mt-12">
            <div className="text-center">
              <p className="font-arco text-7xl title-stroke text-white">
                mint your
              </p>
              <p className="font-arco text-7xl title-stroke text-white">
                <span className="text-[#ffec3e]">Oasis garden</span> NFT
              </p>
            </div>
            <div className="flex gap-12 mt-20 justify-center p-4 flex-col md:flex-row md:p-8">
              <img
                src={gardenGif}
                alt="FlowerFamGarden"
                className="md:h-[490px] rounded-[20px]"
              />
              <div className="bg-white p-6 md:p-8 flex-1 max-w-[500px] pr-2 rounded-[40px]">
                <div className="text-textColor font-morh-sb text-lg">
                  <p>Are you lucky enough to</p>
                  <p>
                    mint <span className="font-morh-b">a Mystic Babylon?</span>
                  </p>
                </div>
                <div className="text-textColor font-morh-sb text-lg mt-4">
                  {currentRound === -1
                    ? "Auction starts at June 28th 6PM CEST"
                    : ""}
                </div>
                <div className="flex mt-6 gap-6">
                  <div>
                    <p className="font-morh-sb mb-2">Supply</p>
                    <p className="bg-gray-100 py-2 px-4 text-2xl text-textColor font-bold rounded-lg">
                      {supplyCount}
                    </p>
                  </div>
                  <div>
                    <p className="font-morh-sb mb-2">Price</p>
                    <p className="bg-green-100 text-green-600 py-2 px-4 text-2xl font-bold rounded-lg">
                      {(price &&
                        (ethToRegularString(price) * mintCount).toFixed(3)) ||
                        0.15}{" "}
                      ETH
                    </p>
                  </div>
                  <div>
                    <p className="font-morh-sb mb-2">Time Left In Stage</p>
                    <p className="bg-red-100 text-red-600 py-2 px-4 text-2xl font-bold rounded-lg">
                      {minutes}:{seconds}
                    </p>
                  </div>
                </div>
                {isConnected ? (
                  <>
                    {userMinted < 5 ? (
                      <>
                        <div className="flex mt-6 gap-6">
                          <div className="flex items-center border-gray-200 border-2 rounded-full gap-4 px-4">
                            <p
                              onClick={() => onDecrease()}
                              className="text-3xl cursor-pointer"
                            >
                              -
                            </p>
                            <p className="font-morh-sb text-3xl mt-[7px]">
                              {mintCount}
                            </p>
                            <p
                              onClick={() => onIncrease()}
                              className="text-3xl cursor-pointer"
                            >
                              +
                            </p>
                          </div>
                          <Button
                            isPrimary
                            loading={minting}
                            onClick={() => onMint()}
                            classes={[
                              "text-lg",
                              "uppercase",
                              "font-morh-b",
                              "text-center",
                            ]}
                          >
                            Mint my garden
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div className="text-textColor font-morh-sb text-lg">
                        <p style={{ color: "red" }}>
                          You have minted 5 Gardens and cannot mint more.
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <Button
                    isPrimary
                    onClick={() => doConnect()}
                    classes={[
                      "mt-8",
                      "uppercase",
                      "!bg-[#ffec3e]",
                      "text-textColor",
                    ]}
                  >
                    Connect wallet
                  </Button>
                )}
                {txHash ? (
                  <div className="mt-8 text-center">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={getTxUrl(txHash)}
                      className="font-morh-sb text-xl"
                    >
                      View TX
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <img src={cloudSvg} alt="Clouds" width="100%" />
        <Faq />
      </div>
      <AlertModal
        message={errorMsg}
        visible={!!errorMsg}
        onClose={() => setErrorMsg()}
      />
    </>
  );
};

export default ProviderHOC(App);
