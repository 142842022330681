import React from "react";

import { ProviderConsumer } from "context/provider";
import { setProvider as providerSetter } from "reducers/provider";

const ProviderHOC = (BaseComponent) => (props) => {
  const [providerConsumer, dispatch] = ProviderConsumer();
  const provider = providerConsumer?.detail || null;

  function setProvider(data) {
    dispatch(providerSetter(data));
  }

  return (
    <BaseComponent
      {...props}
      provider={provider}
      isConnected={!!provider?.address}
      setProvider={(data) => setProvider(data)}
    />
  );
};

export default ProviderHOC;
