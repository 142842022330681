import { ethers } from "ethers";

export const twoDecimals = (str) => {
  const split = str.split(".");
  if (split.length > 1) {
    return split[0] + "." + split[1].substring(0, 2);
  } else {
    return str;
  }
};

export const threeDecimals = (str) => {
  const split = str.split(".");
  if (split.length > 1) {
    return split[0] + "." + split[1].substring(0, 3);
  } else {
    return str;
  }
};

export const ethToRegularString = (eth) => {
  if (!eth || !ethers.BigNumber.isBigNumber(eth)) {
    return "0.0";
  }
  return threeDecimals(ethers.utils.formatEther(eth));
};

export const getTxUrl = (hash) => {
  return `https://etherscan.io/tx/${hash}`;
};
