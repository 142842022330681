import { useState } from "react";
import { ReactComponent as FaqIconSvg } from "assets/graphics/_.svg";

const FAQ_ITEMS = [
  {
    title: "How does the Auction play out?",
    content: (
      <p>
        Start 0.15 ETH
        <br />
        End 0.05 ETH
        <br />
        0.01 drop every 20 minutes
        <br />
        3.000 NFTs
        <br />5 NFTs per wallet
      </p>
    ),
  },
  {
    title: "Where can I enter the Oasis?",
    content: (
      <a
        href="https://oasis.flowerfam.earth/"
        style={{ textDecoration: "underline" }}
      >
        https://oasis.flowerfam.earth/
      </a>
    ),
  },
  {
    title: "Where can I read about the Tokenomics or Garden NFTs? ",
    content: (
      <a
        href="https://flowerfam.gitbook.io/oasis-garden/"
        style={{ textDecoration: "underline" }}
      >
        https://flowerfam.gitbook.io/oasis-garden/
      </a>
    ),
  },
  {
    title: "Where can I buy $honeycoin?",
    content: (
      <a
        href="https://www.dextools.io/app/ether/pair-explorer/0xdf44622fc41d706d6d3cfe1d22dcf80b170c2ab2"
        style={{ textDecoration: "underline" }}
      >
        We recommend Dextools.
      </a>
    ),
  },
  {
    title: "How many Gardens are there?",
    content: (
      <p>
        Approximately 6.000, depending how many will be claimed on Thursdays
        Garden claim.
      </p>
    ),
  },
  {
    title: "Who can participate in the auction?",
    content: <p>Everybody</p>,
  },
  {
    title: "Special Rewards for early participants?",
    content: (
      <p>
        First 690 Gardens that are auctioned away will enter a Raffle for:
        <br />
        2 x Mount Olympus
        <br />
        4 x Pandora’s Rock
        <br />
        5x New Generation Flowers
        <br />
        2x OG Collection Prodigies
        <br />
        4x Mighty Bees
      </p>
    ),
  },
  {
    title: "What happens to mint proceedings?",
    content: (
      <p>
        Hiring and retaining talent
        <br />
        Adding to Liqudity Pool of $honeycoin
        <br />
        Funding Community-Driven Initiatives
        <br />
        Expanding the Flower Fam Ecosystem
        <br />
        Building more dope sh*t
      </p>
    ),
  },
  {
    title: "Why 0.15?",
    content: (
      <p>
        If adjusted on current eth price it’s the same as our 0.069 mint price.
        This means that in any case you will mint Gardens for less than the OG
        collection. While the main collection is 5x since mint…
      </p>
    ),
  },
  {
    title: "How many NFTs can I mint?",
    content: <p>5 NFTs per wallet.</p>,
  },
  {
    title: "I load up on Gardens, what now?",
    content: (
      <p>
        Get your hands on a New Generation or OG Flower, before you get priced
        out. Stack up $honeycoin and wait until the reveal on Thursday when you
        can stake your Garden
      </p>
    ),
  },
  {
    title: "Why not minting on $honeycoin?",
    content: (
      <p>
        Why ETH? Pulling off a successful and issue-free mint on $honeycoin is
        something that is both from a development and operations perspective
        very difficult. Swapping issues, balance problems, depositing bugs, plus
        considering the necessary onboarding experience for all new buyers. We
        want to provide a very smooth start to the next chapter. No Flower left
        behind.
      </p>
    ),
  },
  {
    title: "Do Flowers like gas fees?",
    content: <p>No, they prefer ERC-721A and an optimized contract.</p>,
  },
  {
    title: "Wen reveal?",
    content: (
      <p>
        On Thursday, the 30th together with the Garden Claim for staked Flowers.
      </p>
    ),
  },
  {
    title: "Wen stake?",
    content: (
      <p>
        The Oasis dashboard is revealed on Thursday, 30th of June together with
        the Garden Claim for staked Flowers. Until then, stake your Flowers,
        have a good time and load up on some $honeycoin.
      </p>
    ),
  },
  {
    title: "How’s $honeycoin doing?",
    content: <p>Check here.</p>,
  },
  {
    title: "Which Species of Garden will I get?",
    content: (
      <p>
        Only the gods of the Oasis know. Gardens come in 4 different species.
        You will find out on Thursday…
      </p>
    ),
  },
  {
    title: "Do I own the IP rights of my Gardens?",
    content: <p>Yes. Do whatever you want with it.</p>,
  },
];

export default function Faq() {
  const [openFaqItems, setOpenFaqItems] = useState([]);

  function onToggleFaqItem(idx) {
    const index = openFaqItems.indexOf(idx);
    if (index > -1) {
      setOpenFaqItems(openFaqItems.filter((i) => i !== idx));
    } else {
      setOpenFaqItems([...openFaqItems, idx]);
    }
  }

  return (
    <div className="container m-auto pt-16 pb-24 px-4 relative">
      <p className="font-arco text-7xl title-stroke text-[#ffec3e] text-center mb-12">
        faq
      </p>
      <div className="faq-wrapper max-w-[800px] m-auto">
        {FAQ_ITEMS.map((item, idx) => {
          const isOpen = openFaqItems.indexOf(idx) > -1;
          return (
            <div key={idx} className="faq-item bg-white rounded-3xl my-6 border-textColor border-[3px]">
              <div
                onClick={() => onToggleFaqItem(idx)}
                className="faq-title px-10 pt-6 pb-5 cursor-pointer flex justify-between"
              >
                <p className="font-morh-b text-xl text-textColor">
                  {item.title}
                </p>
                <FaqIconSvg
                  className={`toggle-faq-icon transition-all ${
                    isOpen ? "active" : ""
                  }`}
                />
              </div>
              <div
                className={`faq-content font-morh-m pb-6 px-10 text-textColor ${
                  isOpen ? "block" : "hidden"
                }`}
              >
                {item.content}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
